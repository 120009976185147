@tailwind base;

@layer base {
  body {
    font-family: 'Inter', sans-serif;
  }
}

.dark {
  background-color: #0F172A;
}

.paginationBttns {
  width: 30%;
  height: 2.5rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-items: center;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.paginationBttns a {
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: 1px solid #CCCCCC;
  color: #555555;
  cursor: pointer;
  background-color: white;
}

.dark .paginationBttns a {
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: 1px solid #CCCCCC;
  color: #c5c3c3;
  cursor: pointer;
  background-color: #1F2937;
}

/* .paginationBttns a:hover {
    color: white;
    background-color: #C55E5E;
  } */

.paginationActive a {
  color: #65AEF9;
  background-color: #EBF5FF;
  border: 1px solid #65AEF9;
}

.dark .paginationActive a {
  color: white;
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
  border: 1px solid white;
}

.paginationDisabled a {
  color: #AAAAAA;
}

a.previousBttn {
  border-bottom-left-radius: 0.313rem;
  border-top-left-radius: 0.313rem;
}

a.nextBttn {
  border-bottom-right-radius: 0.313rem;
  border-top-right-radius: 0.313rem;
}

/* slider cobaan */
.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-slider {
  width: 600px;
  margin-top: 80px;
  z-index: 20;
}

.image-slider-noactive {
  width: 500px;
  opacity: 0.4;
  z-index: 0;
}

.right-arrow {
  position: absolute;
  top: 55%;
  right: 422px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 55%;
  left: 422px;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide-active {
  z-index: 10;
}

/* slider swiper */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 75%;
}

.active-border {
  /* border: 2px solid rgba(139, 61, 255) !important; */
  border-bottom: 8px solid #4ba8ff !important;
}

.active-button {
  border: 3px solid rgb(96 165 250 / var(--tw-border-opacity)) !important;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity)) !important;
}


@tailwind components;

@tailwind utilities;